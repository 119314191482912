import React from "react";
import {Box} from "@mui/material";
import logo from '../assets/logo-lilly-red.png'

export const LogoHeader = ({children}) => {
    return(
        <Box className={'form-bkg'}>
            <Box sx={{textAlign:'center', width:'100%', pt:6}}>
                <img src={logo} style={{width:'100%',maxWidth:'13.563rem'}}/>
                {children}
            </Box>
        </Box>
    )
}
