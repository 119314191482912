import React, {useEffect, useState} from "react";
import {LogoHeader} from "../layouts/LogoHeader";
import {
    Backdrop, Box,
    Button, capitalize,
    Card, CardContent,
    CircularProgress, Collapse,
    Container, FormControl, InputLabel, MenuItem, Select,
    Snackbar,
    Stack, TextareaAutosize,
    TextField,
    Typography
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export const Form = () => {
    const conference_id = 'vkEYvFu15ZKNNaHJPt52'
    const [fields, setFields] = useState([])
    let navigate = useNavigate()
    const [values, setValues] = useState({
        domanda: ''
    })
    const [loading, setLoading] = useState(true)
    const [success, setSuccess] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(false)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        axios.get('https://api-tool-press-yn26wrnl4q-ey.a.run.app/' + conference_id + '/columns')
            .then(response => {
                const obj = {}
                setFields(response.data)
                response.data.map(r => obj[r] = '')
                setValues({...values, ...obj})
                setLoading(false)
            })
            .catch(() => {
                navigate("/", {replace: true})
            })
    }, [])

    const handleChangeValue = (e, key) => {
        setValues({...values, [key]: e.target.value})
    }

    const postQuestions = async () => {
        setSending(true)
        await axios.post('https://api-tool-press-yn26wrnl4q-ey.a.run.app/' + conference_id + '/question', values)
        setSending(false)
        setValues(prevState => ({...prevState, domanda: ''}))
    }


    const sendQuestion = (e) => {
        e.preventDefault()

        postQuestions()
            .then(() => {
                setSuccess(true)
                setOpen(true)
                setTimeout(() => setOpen(false),5000)
            })
            .catch(() => setError(true))

    }

    const handleSetError = (bool) => {
        setSuccess(bool)
    }


    return (
        <LogoHeader>
            <Snackbar open={error} autoHideDuration={6000} onClose={() => handleSetError(false)}>
                <Alert onClose={() => handleSetError(false)} severity="error" sx={{width: '100%'}}>
                    C'è stato un errore, si prega di riprovare
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loading || sending}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>

            <Container maxWidth={'sm'}>
                <Typography variant={"h4"} fontWeight={'bold'} sx={{py: 3}} textTransform={'uppercase'} color={'#D62718'}>
                    Invia la tua domanda
                </Typography>
                <Card>
                    <CardContent>
                        <form style={{textAlign: 'left'}} onSubmit={sendQuestion}>
                            <FormControl fullWidth variant={'standard'}>
                                <InputLabel id="demo-simple-select-label">Per:</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={values['Per']}
                                    label="Per:"
                                    required
                                    onChange={(e) => handleChangeValue(e, 'Per')}
                                >
                                    <MenuItem value={'Bonora E.'}>Bonora E.</MenuItem>
                                    <MenuItem value={'Fadini G.P.'}>Fadini G.P.</MenuItem>
                                    <MenuItem value={'Mannucci E.'}>Mannucci E.</MenuItem>
                                    <MenuItem value={'Natali A.'}>Natali A.</MenuItem>
                                    <MenuItem value={'Penno G.'}>Penno G.</MenuItem>
                                    <MenuItem value={'Tutti'}>Tutti</MenuItem>
                                </Select>
                            </FormControl>
                            <Typography variant={'h6'} textAlign={'left'} sx={{pt:2}}>Domanda</Typography>
                            <Stack direction={"column"} spacing={4}>
                                {/*fields.map(f =>
                                    <Box>
                                        <TextField sx={{width: '100%'}} variant={"standard"}
                                                   label={capitalize(f)}
                                                   required
                                                   value={values[f]} onChange={(e) => handleChangeValue(e, f)}
                                        />
                                    </Box>
                                )*/}
                                <Box sx={{width: '100%'}}>
                                    <TextField multiline required value={values['domanda']}
                                                      minRows={4}
                                                      onChange={(e) => handleChangeValue(e, 'domanda')}
                                                      style={{width: '98%', resize: 'none'}}
                                                      variant={"outlined"} placeholder={'Scrivi qui la tua domanda'}/>
                                </Box>
                                <Box sx={{textAlign: 'center'}}>
                                    <Button variant={"contained"} type={"submit"}>
                                        {success ? "Invia un'altra domanda" : 'Invia domanda'}
                                    </Button>
                                </Box>
                            </Stack>
                        </form>
                        <Collapse in={open}>
                            <Box sx={{pt:2}}>
                                <Typography>
                                    Domanda inviata con successo
                                </Typography>
                            </Box>
                        </Collapse>
                    </CardContent>
                </Card>
            </Container>
        </LogoHeader>
    )
}
